// Environment: Dev

export const FLAG_CME_CASE = "cmeCase";
export const FLAG_ANONYMOUS_POSTING = "anonymousPosting";
export const FLAG_ACCEPTED_ANSWER = "acceptedAnswer";
export const FLAG_PUBLIC_GROUPS = "publicGroups";
export const FLAG_FEEDCARD_LABELS = "feedcardLabels";
export const FLAG_QUIZZES_FEED = "quizzesFeed";
export const FLAG_POSTS = "posts";

export const FeatureFlags = {
  [FLAG_CME_CASE]: true,
  [FLAG_ANONYMOUS_POSTING]: true,
  [FLAG_ACCEPTED_ANSWER]: true,
  [FLAG_PUBLIC_GROUPS]: true,
  [FLAG_FEEDCARD_LABELS]: true,
  [FLAG_QUIZZES_FEED]: true,
  [FLAG_POSTS]: true
};

// Not a lot going on with this right now, but allows us to expand
// later if needed.
const checkFlag = (name) => {
  return FeatureFlags[name] || false;
};
export default checkFlag;
