/** @format */
import React from "react";
import { useSelector } from "react-redux";
import { ListGroup } from "reactstrap";
import PropTypes from "prop-types";
import { isEmpty, inRange } from "lodash";
import {
  CONTENT_TYPE,
  QUESTION_CONTENT_TYPES
} from "../../constants/content-type";
import classNames from "../../utils/class-names-utils";
import { isPoll } from "../../utils/tactic-utils";
import i18n from "../../utils/i18n";
import FeedProgressTag from "./FeedProgressTag";
import TagButton from "./TagButton";
import { decideIfPagingIsActive } from "../../utils/case-utils";

const INCLUSIVE_MODIFIER = 1;

/**
 * Tags made of case label(s) and content type.
 *
 * If it's a series question type (poll, quiz, Clinical moment etc), show progress also.
 * If it's a tactic (spon con, question etc), don't show labels.
 */
const TagsContainer = ({
  className,
  caseContent,
  currentIndex = 0,
  caseState = {},
  isFeedCard
}) => {
  /** ********************************** CONFIG ***************************************/
  const caseLabelStore = useSelector((state) => state.caseLabels);
  const { labels, contentType, contentCount, caseUuid, features } = caseContent;
  const _isPoll = caseContent.isPoll || isPoll(caseContent);
  const isQuizSeries = contentType === CONTENT_TYPE.QUIZ_SERIES;
  const tagsList = [];
  const { showLabels } = features;
  const isPagingActive = decideIfPagingIsActive(
    caseContent.publishedAt,
    caseContent.isPagingCase
  );
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  if (!isFeedCard && isPagingActive) {
    tagsList.push(
      <TagButton key={`${caseUuid}-paging`} color="red" icon="paging" outline>
        {i18n.t("CaseCard.pagingLabel")}
      </TagButton>
    );
  }

  // Build tags for labels
  if (showLabels && !isEmpty(labels)) {
    labels.forEach((label) => {
      const tagLabel = caseLabelStore.labelByKind[label];
      // only show labels that are in the store, as the others are considered hidden
      if (tagLabel) {
        tagsList.push(
          <TagButton key={`${caseUuid}-${tagLabel}`} color="aqua" outline>
            {tagLabel}
          </TagButton>
        );
      }
    });
  }

  if (
    QUESTION_CONTENT_TYPES.includes(contentType) ||
    contentType === CONTENT_TYPE.QUIZ_SUMMARY
  ) {
    const questionTypeLabel = (() => {
      const isSingleQuiz = contentType === CONTENT_TYPE.QUIZ;
      if (_isPoll) {
        return isSingleQuiz
          ? i18n.t("common.quickPoll")
          : i18n.t("common.poll");
      } else {
        return isSingleQuiz
          ? i18n.t("common.quickQuiz")
          : i18n.t("common.quiz");
      }
    })();

    tagsList.unshift(
      <TagButton
        key={`${caseUuid}-question-type-${questionTypeLabel}`}
        color={_isPoll ? "aqua" : "denim-blue"}>
        {questionTypeLabel}
      </TagButton>
    );
  }

  if (
    !isFeedCard &&
    isQuizSeries &&
    inRange(currentIndex, contentCount + INCLUSIVE_MODIFIER)
  ) {
    tagsList.push(
      <TagButton
        key={`${caseUuid}-progress-indicator`}
        color={_isPoll ? "aqua" : "denim-blue"}
        outline>
        {`${currentIndex + 1} / ${contentCount}`}
      </TagButton>
    );
  }

  if (isFeedCard && isQuizSeries) {
    tagsList.push(
      <FeedProgressTag
        key={`${caseUuid}-progress`}
        {...{
          ...caseState,
          /**
           * Handle not started. Uses feed contentCount data
           * since stateDB doc doesn't exist in this scenario.
           */
          numQuestions: caseState.numQuestions || contentCount
        }}
        caseUuid={caseUuid}
        isPoll={_isPoll}
      />
    );
  }

  /** ********************************** RENDER ***************************************/

  if (!tagsList.length) {
    return null;
  }

  if (isFeedCard && !isQuizSeries) {
    return null;
  }

  return (
    <ListGroup
      className={classNames(
        "list-group-spacing-x-2 flex-wrap mb-n2",
        className
      )}
      horizontal>
      {tagsList}
    </ListGroup>
  );
};

TagsContainer.propTypes = {
  caseContent: PropTypes.shape({
    contentType: PropTypes.string,
    contentCount: PropTypes.number,
    questionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        isAnswer: PropTypes.bool
      })
    ),
    labels: PropTypes.arrayOf(PropTypes.string),
    caseType: PropTypes.string,
    features: PropTypes.shape({
      showLabels: PropTypes.bool
    })
  }).isRequired,
  currentIndex: PropTypes.number,
  caseState: PropTypes.shape({
    numCorrect: PropTypes.number,
    currentQuestion: PropTypes.shape({
      displayOrder: PropTypes.number
    }),
    status: PropTypes.string
  }),
  isFeedCard: PropTypes.bool
};

export default TagsContainer;
