import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { trackUngatedCTA } from "../../actions/metrics.actions";
import useAnonUserExists from "../../hooks/useAnonUserExists";
import useGatedBlockCounter from "../../hooks/useGatedBlockCounter";
import useLoginModalClick from "../../hooks/useLoginModalClick";
import useSignUpModalClick from "../../hooks/useSignUpModalClick";
import {
  CASE_CARD_ORIGIN,
  SHARE_CONTEXT,
  SHARE_LOCATIONS,
  UNGATED_ACTIONS
} from "../../metrics/constants.metrics";
import BlockedCaseContentDetail from "./BlockedCaseContentDetail";
import BlockedQuizDetail from "./BlockedQuizDetail";
import CaseActions from "./CaseActions";
import CaseUserCell from "./CaseUserCell";
import ShareCaseDialog from "./ShareCaseDialog";

const BlockedCaseContent = ({ itemContent, commentStatus, caseDetails }) => {
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const userExists = useAnonUserExists();
  const { sessionCount, countLimit } = useGatedBlockCounter();
  const { showLoginModal } = useLoginModalClick();
  const { showSignUpModal } = useSignUpModalClick();
  const quizLayout = itemContent?.isPoll || itemContent?.isQuiz;

  const loginClick = {
    onClick: userExists
      ? (e) => {
          trackUngatedCTA(
            UNGATED_ACTIONS.CTA_LOCATION.BLOCKED_IMAGE,
            userExists
          );
          showLoginModal(e);
        }
      : (e) => {
          trackUngatedCTA(
            sessionCount > countLimit
              ? UNGATED_ACTIONS.CTA_LOCATION.UNLIMITED_ACCESS
              : UNGATED_ACTIONS.CTA_LOCATION.BLOCKED_IMAGE,
            userExists
          );
          showSignUpModal(e);
        }
  };

  return (
    <Card className="mb-2">
      <CardBody className="d-inline-flex justify-content-between align-items-top w-100 bg-white border-0">
        <CaseUserCell
          caseContent={itemContent}
          displayFollowUser={!quizLayout}
        />
        <CaseActions
          caseContent={itemContent}
          caseNotification={() => {}}
          from={CASE_CARD_ORIGIN.DETAIL}
          commentStatus={commentStatus}
          hideShareText={false}
          onShareClick={() => setIsShareDialogOpen(true)}
        />
      </CardBody>

      {quizLayout && (
        <BlockedQuizDetail itemContent={itemContent} loginClick={loginClick} />
      )}
      {!quizLayout && (
        <BlockedCaseContentDetail
          itemContent={itemContent}
          caseDetails={caseDetails}
          loginClick={loginClick}
        />
      )}

      <ShareCaseDialog
        isOpen={isShareDialogOpen}
        caseContent={itemContent ?? {}}
        location={SHARE_LOCATIONS.TOP_BAR}
        context={SHARE_CONTEXT.DETAILED_VIEW}
        onClosed={() => setIsShareDialogOpen(false)}
      />
    </Card>
  );
};

export default BlockedCaseContent;
