import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { performSignout, resetGatedModal } from "../../actions/global.actions";
import GATED_MODAL_TYPE from "./GatedModalType";
import i18n from "../../utils/i18n";
import CustomIcon from "../custom-icon/CustomIcon";
import { useParams } from "react-router-dom";
import Dialog from "../dialog/Dialog";
import DIALOG_TYPE from "../dialog/DialogType";
import { trackGatedCTA } from "../../actions/metrics.actions";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import useSignUpModalClick from "../../hooks/useSignUpModalClick";
import useLoginModalClick from "../../hooks/useLoginModalClick";

const GatedModal = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();

  const { showSignUpModal } = useSignUpModalClick();
  const { showLoginModal } = useLoginModalClick();

  const caseUuid = routeParams.caseId;
  const caseStatus = useSelector((state) => state.case.cases[caseUuid]);
  const gatedType = useSelector((state) => state.global.gatedMessageType);
  const originalAction = useSelector(
    (state) => state.global.gatedMessageOrigin
  );

  const userExists = !!useSelector((state) => state.user?.userUuid);

  const details = caseStatus?.data || {};
  const campaignUuid = details?.campaignUuid;
  const caseType = details.caseType;

  const [iconType, setIconType] = useState(null);
  const [title, setTitle] = useState(null);
  const [source, setSource] = useState(null);

  const clearGatedMessage = () => {
    dispatch(resetGatedModal());
  };

  const onClosed = () => {
    clearGatedMessage();
  };

  const onSignIn = () => {
    trackGatedCTA({
      campaignUuid,
      caseUuid,
      caseType,
      source,
      action: UNGATED_ACTIONS.ACTION.SIGN_IN,
      originalAction
    });
    clearGatedMessage();
    showLoginModal();
  };

  const onBecomeMember = () => {
    trackGatedCTA({
      campaignUuid,
      caseUuid,
      caseType,
      source,
      action: UNGATED_ACTIONS.ACTION.SIGN_UP,
      originalAction
    });
    clearGatedMessage();
    showSignUpModal();
  };

  useEffect(() => {
    if (gatedType) {
      let newTitle = `Gated.${gatedType}`;
      setTitle(i18n.t(`Gated.${gatedType}`));
      if (gatedType === GATED_MODAL_TYPE.CERTIFICATE) {
        dispatch(performSignout(false));
      } else {
        dispatch(performSignout(true));
      }
      switch (gatedType) {
        case GATED_MODAL_TYPE.CERTIFICATE:
          setIconType("certificate_circle");
          setSource(UNGATED_ACTIONS.ORIGIN.GENERIC_MODAL);
          break;
        case GATED_MODAL_TYPE.COMMENT:
          setIconType("comment_circle");
          setSource(UNGATED_ACTIONS.ORIGIN.COMMENT_MODAL);

          break;
        case GATED_MODAL_TYPE.REACT:
          setIconType("react_circle");
          setSource(UNGATED_ACTIONS.ORIGIN.REACT_MODAL);

          break;
        case GATED_MODAL_TYPE.PROFILE:
          setIconType("profile_circle");
          setSource(UNGATED_ACTIONS.ORIGIN.PROFILE_MODAL);

          break;
        case GATED_MODAL_TYPE.GENERIC:
        default:
          setIconType("cme_circle");
          setSource(UNGATED_ACTIONS.ORIGIN.GENERIC_MODAL);
          newTitle = `Gated.${GATED_MODAL_TYPE.GENERIC}`;

          break;
      }
      setTitle(i18n.t(newTitle));
    }
  }, [gatedType]);

  const contents = (
    <>
      <CustomIcon icon={iconType} size={48} className="mb-3" />
      <p className="text-20 helv-med">{title}</p>
    </>
  );
  const cancelProps = !userExists
    ? {
        onConfirm: onBecomeMember,
        confirmText: i18n.t("Gated.Banner.becomeMember"),
        onCancelClick: onSignIn,
        cancelText: i18n.t("Gated.SignIn")
      }
    : {
        onConfirm: onSignIn,
        confirmText: i18n.t("Gated.SignIn")
      };

  return (
    <Dialog
      centered
      isOpen={gatedType != null}
      onClosed={onClosed}
      onCancel={onClosed}
      body={contents}
      type={DIALOG_TYPE.GATED}
      {...cancelProps}
    />
  );
};

GatedModal.propTypes = {};

export default GatedModal;
