import React from "react";
import { getUrlWithRedirect } from "../../utils/route-utils";
import { SIGN_IN, SIGN_UP } from "../../constants/routes";
import { Link } from "react-router-dom";
import i18n from "../../utils/i18n";
import useGatedBlockCounter from "../../hooks/useGatedBlockCounter";
import useAnonUserExists from "../../hooks/useAnonUserExists";
import { trackUngatedCTA } from "../../actions/metrics.actions";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import useLoginModalClick from "../../hooks/useLoginModalClick";
import useSignUpModalClick from "../../hooks/useSignUpModalClick";

const GatedCaseNotice = ({ visible = false, className = "" }) => {
  /** ********************************** CONFIG ***************************************/

  const { currentCount, countLimit } = useGatedBlockCounter();
  const userExists = useAnonUserExists();
  const computedCount = countLimit - currentCount + 1; // lag by 1
  const textKey =
    currentCount === countLimit
      ? "Gated.CaseLimit.lastChanceText"
      : "Gated.CaseLimit.countText";

  const { showLoginModal } = useLoginModalClick();
  const { showSignUpModal } = useSignUpModalClick();

  const loginClick = {
    onClick: userExists
      ? (e) => {
          trackUngatedCTA(
            UNGATED_ACTIONS.CTA_LOCATION.UNLIMITED_ACCESS,
            userExists
          );
          showLoginModal(e);
        }
      : (e) => {
          trackUngatedCTA(
            UNGATED_ACTIONS.CTA_LOCATION.UNLIMITED_ACCESS,
            userExists
          );
          showSignUpModal(e);
        }
  };
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  if (!visible) return null;

  return (
    <div
      className={`alert bg-lighter-gray-alt text-14 px-3 py-2 helv-med ${className}`}>
      {i18n.t(textKey).replace("$COUNT", computedCount)}{" "}
      <Link
        to={getUrlWithRedirect(userExists ? SIGN_IN : SIGN_UP)}
        {...loginClick}>
        {" "}
        {i18n.t("Gated.CaseLimit.signUpText")}
      </Link>
    </div>
  );
};

export default GatedCaseNotice;
