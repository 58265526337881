/** @format */
export const buildOptions = Object.freeze({
  DEV: "dev",
  QA: "qa",
  STAGING: "staging",
  RELEASE: "release"
});
// superAdminUsers will be users who have access to the dev screens in the app
export default {
  imgixUrl: "https://figure1.imgix.net/",
  versionBaseUrl: "https://s3.amazonaws.com/figure1-version-url/",
  buildEnv: buildOptions.DEV,
  appSchema: "figure1pro-dev://",
  superAdminUsers: [],
  mixpanelToken: "78b2bfa6515a989cc9559c382f5bf19d",
  mixpanelUrl: "https://tracking-proxy-dev.web.app/tracking",
  smartlookToken: "87b37811be41e538c9105540138f4bf3275623c8",
  uxcamToken: "0tlo4utowvgoajp",
  iterableAPIKey: "e0a097b133bc4a528c39ae8115915f59",
  gtm_token: "GTM-WT3JTC7",
  gtm_auth: "MYTSu_MaF1yVWgQwhg5XDg",
  gtm_preview: "env-14",
  ipinfo: "5db3d1e380ae71"
};
